import React from 'react'
import { ChevronRightIcon, HomeIcon, PlusCircleIcon } from '@heroicons/react/20/solid';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useModal } from 'components/Modal/Simple';


const examplePages = [
    { name: 'Projects', href: '#' },
    { name: 'Project Nero', href: '#' },
]

const Breadcrumbs = ({ pages = examplePages, onPlusClicked = (length) => { } }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const partIndex = searchParams.get('part');
    const concernIndex = searchParams.get('concern');

    const onBreadcrumbsClicked = (index) => {
        if(index === 0) {
            navigate(`${location.pathname}?part=${partIndex}`);
        }
        else if(index === 1) {
            navigate(`${location.pathname}?part=${partIndex}&concern=${concernIndex}`);
        }
    }

    return (
        <nav aria-label="Breadcrumb" className="flex">
            <ol role="list" className="flex items-center space-x-1">
                <li>
                    <div>
                        <a className="text-gray-400 hover:text-gray-500" onClick={() => { navigate("/") }}>
                            <HomeIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0" />
                            <span className="sr-only">Home</span>
                        </a>
                    </div>
                </li>
                {pages.map((page, index) => (
                    <li key={page.name}>
                        <div className="flex items-center">
                            <ChevronRightIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400" />
                            <a
                                onClick={() => { onBreadcrumbsClicked(index) }}
                                className="ml-1 text-sm font-medium text-gray-500 hover:text-gray-700 text-nowrap"
                            >
                                {page.name}
                            </a>
                        </div>
                    </li>
                ))}
                <li hidden={pages.length===3}>
                    <div className="flex items-center">
                        <ChevronRightIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400" />
                        <a className="ml-1 text-gray-400 hover:text-gray-500" onClick={() => { onPlusClicked(pages.length) }}>
                            <PlusCircleIcon aria-hidden="true" className="size-4 flex-shrink-0" />
                        </a>
                    </div>
                </li>
            </ol>
        </nav>
    )
}

export default Breadcrumbs;