import React from "react";
import { data } from "./example";
import Card from "components/Card";
import { StarIcon } from "@heroicons/react/20/solid";
import PageTitle from "components/Title/Page";
import Search from "containers/Search";
import numeral from "numeral";
import { useLocation, useSearchParams } from "react-router-dom";

export const EventCard = ({ mainImg, clinicName, name, rating, nReview, nReceipt, price, originPrice }) => (
    <Card>
        <div className="group rounded-md overflow-hidden">
            <div className="flex items-center">
                <img
                    src={mainImg}
                    alt="clinic"
                    className="h-24 w-24 p-2 object-center group-hover:opacity-75"
                />
                <div className="p-2 flex flex-col text-xs space-y-2">
                    <div>
                        <h1 className="font-normal font-sans text-gray-600">
                            {clinicName}
                        </h1>
                        <h1 className="font-normal font-sans">
                            {name}
                        </h1>
                    </div>
                    <div className="flex items-center">
                        <StarIcon className="w-4 h-4 text-yellow-400" />
                        <span className="text-gray-500 text-xs">
                            {rating}
                        </span>
                        <span className="ms-[2px] text-gray-600 text-xs">
                            {`口コミ${nReview}件症例${nReceipt}件`}
                        </span>
                    </div>
                    <div className="flex items-center">
                        <span className="text-pink-700 text-xs">
                            {`¥${numeral(price).format("0,0")}`}
                        </span>
                        <span className="text-gray-600 text-[10px] ps-1">
                            {` | 通常価格：¥${numeral(originPrice).format("0,0")}`}
                        </span>
                    </div>
                </div>
            </div>

        </div>
    </Card>
)

const EventPage = () => {
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const partIndex = searchParams.get("part");
    const concernIndex = searchParams.get("concern");
    const treatmentIndex = searchParams.get("treatment");

    let length = 100;
    if (treatmentIndex) {
        length = 1;
    }
    else if (concernIndex) {
        length = 2;
    }

    if (location.pathname.startsWith("/bookmark")) {
        length = 2;
    }

    const targetData = data.filter((item, index) => {
        return index < length;
    })


    return (
        <div>
            <div className="pt-3 px-2">
                <PageTitle>
                    イベント一覧
                </PageTitle>
            </div>
            <div className="px-2 pt-2 text-sm">
                {`${targetData.length}件のイベント一覧`}
            </div>
            {targetData.map((item, index) => (
                <EventCard
                    key={index}
                    mainImg={item.mainImg}
                    clinicName={item.clinicName}
                    name={item.name}
                    rating={item.rating}
                    nReview={item.nReview}
                    nReceipt={item.nReceipt}
                    price={item.price}
                    originPrice={item.originPrice}
                />
            ))}
        </div>
    )
}

export default EventPage;