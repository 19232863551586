import React from 'react';
import logo from 'assets/logo/logo-no-background.png';
import Card from 'components/Card';
import { data as clinicData } from 'pages/Clinic/example';
import { data as eventData } from 'pages/Event/example';
import { data as reviewData } from 'pages/Review/example';
import { ClinicCard } from 'pages/Clinic';
import { EventCard } from 'pages/Event';
import { ReviewCard } from 'pages/Review';
import PageTitle from 'components/Title/Page';
import Button from 'components/Form/Button';
import { ChevronRightIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import Search from 'containers/Search';
import { parts } from './example';

const Home = () => {
    const navigate = useNavigate();

    return (
        <div className="flex flex-col space-y-2">
            <div>
                <div className="px-2 pt-2">
                    <PageTitle>
                        ホーム
                    </PageTitle>
                </div>
                {/* <Search /> */}
                <div className="px-2 pt-2">
                    <PageTitle>
                        部位からクリニックを探す
                    </PageTitle>
                </div>
                <div className="grid grid-cols-4 px-2 gap-3 px-4">
                    {parts.map((item, index) => (
                        <div className="flex justify-center shadow ring-1 ring-black ring-opacity-5 rounded-lg cursor-pointer" onClick={() => { navigate(`/contents/events?part=${index}`) }}>
                            <div className="p-2">
                                <div className="flex justify-center text-pink-300">
                                    <UserCircleIcon className="size-7" />
                                </div>
                                <span className="flex justify-center text-sm">
                                    {item.name}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="px-2 pt-2">
                    <PageTitle>
                        人気のクリニック
                    </PageTitle>
                </div>
                <div>
                    {clinicData.map((item, index) => (
                        <ClinicCard
                            key={index}
                            mainImg={item.mainImg}
                            name={item.name}
                            rating={item.rating}
                            nReview={item.nReview}
                            nReceipt={item.nReceipt}
                            nEvent={item.nEvent}
                        />
                    ))}
                </div>
                <div className="px-2">
                    <Button onButtonClicked={() => { navigate("/clinic") }}>
                        <span>
                            もっと見る
                        </span>
                        <ChevronRightIcon className="size-4" />
                    </Button>
                </div>
            </div>
            <div>
                <div>
                    <div className="px-2 pt-2">
                        <PageTitle>
                            人気のイベント
                        </PageTitle>
                    </div>
                    <div>
                        {eventData.map((item, index) => (
                            <EventCard
                                key={index}
                                mainImg={item.mainImg}
                                clinicName={item.clinicName}
                                name={item.name}
                                rating={item.rating}
                                nReview={item.nReview}
                                nReceipt={item.nReceipt}
                                price={item.price}
                                originPrice={item.originPrice}
                            />
                        ))}
                    </div>
                    <div className="px-2">
                        <Button onButtonClicked={() => { navigate("/event") }}>
                            <span>
                                もっと見る
                            </span>
                            <ChevronRightIcon className="size-4" />
                        </Button>
                    </div>
                </div>
                <div>
                    <div className="px-2 pt-2">
                        <PageTitle>
                            人気のレビュ
                        </PageTitle>
                    </div>
                    <div>
                        {reviewData.map((item, index) => (
                            <ReviewCard
                                key={index}
                                rating={item.rating}
                                treatmentName={item.treatmentName}
                                price={item.price}
                                text={item.text}
                                clinicName={item.clinicName}
                            />
                        ))}
                    </div>
                    <div className="px-2">
                        <Button onButtonClicked={() => { navigate("/review") }}>
                            <span>
                                もっと見る
                            </span>
                            <ChevronRightIcon className="size-4" />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
