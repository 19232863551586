export const data = [
    {
        name: "全顔ハイフ（400shot）｜ウルトラセルQ＋｜超音波を当ててたるみを引き締める",
        clinicName:"L'amour clinic Tokyo",
        mainImg: "https://image2.gnsister.com/images/direct/1698936428762_84342fb353594e589590f4f65bb86a52.png?originalImageWidth=1080&originalImageHeight=473",
        rating: 9.8,
        nReview: 1012,
        nReceipt: 632,
        price: 84500,
        originPrice: 150000,
    },
    {
        name: "《VISIA肌診断付》選べるピーリング｜全顔3回｜肌悩みに合った薬剤をご提案💕",
        clinicName: "Karis Beauty Clinic",
        mainImg: "https://image2.gnsister.com/images/direct/1653448514291_512bd969a46741a98362f81e655aeeda.jpg?originalImageWidth=1080&originalImageHeight=809",
        rating: 9.7,
        summary: "Karis Beauty Clinicは、東京都港区南青山にある美容外科クリニックです。",
        nReview: 1252,
        nReceipt: 105,
        price: 30000,
        originPrice: 60000,
    },
    {
        name: "貴族フィラー｜アラガン社製ジュビダームビスタ®ボリューマXC｜ほうれい線改善✨症例付メニュー📷｜即時予約可能⏰",
        clinicName: "シティタワー新宿クリニック",
        mainImg: "https://image2.gnsister.com/images/direct/1688714165788_91c43ad5e61b4c83a2669352c4c83e29.jpg?originalImageWidth=1080&originalImageHeight=420",
        rating: 9.6,
        summary: "シティタワー新宿クリニックは、東京都新宿区新宿にある美容外科クリニックです。",
        nReview: 202,
        nReceipt: 32,
        price: 45000,
        originPrice: 80000,
    },
    {
        name: "糸リフト12本LUXX M1 PDO＋ヒアルロン酸1cc｜局所・笑気麻酔代込✨追加料金でアラガンもご案内可💓｜即時予約可能⏰",
        clinicName: "日本美容・医療クリニック",
        mainImg: "https://image2.gnsister.com/images/direct/1690510766167_e5e1c486c6e447d697299c068ce6f1e9.jpg?originalImageWidth=1080&originalImageHeight=420",
        rating: 9.5,
        summary: "日本美容・医療クリニックは、東京都港区南青山にある美容外科クリニックです。",
        nReview: 102,
        nReceipt: 52,
        price: 55000,
        originPrice: 100000,
    }
]