import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import PublicBottomNav from 'containers/BottomNav/public';
import PublicSiteRoute from 'containers/SiteRoute/public';

import "./App.scss";

function App() {
    return (
        <div className="main-container">
            <div className="main-content">
                <div className="bg-gradient-to-bl from-pink-300 to-transparent bg-pink-100">
                    {/* We've used 3xl here, but feel free to try other max-widths based on your needs */}
                    <div className="relative mx-auto max-w-md bg-white shadow-2xl">{/* Content goes here */}
                        <div className="min-h-screen">
                            <PublicSiteRoute></PublicSiteRoute>
                        </div>
                        <PublicBottomNav></PublicBottomNav>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;