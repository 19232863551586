import React from "react";
import { data } from "./example";
import Card from "components/Card";
import { StarIcon } from "@heroicons/react/20/solid";
import PageTitle from "components/Title/Page";
import Search from "containers/Search";
import { useLocation, useSearchParams } from "react-router-dom";

export const ClinicCard = ({mainImg, name, rating, nReview, nReceipt, nEvent}) => (
    <Card>
        <div className="group rounded-md overflow-hidden">
            <img
                src={mainImg}
                alt="clinic"
                className="h-36 w-full object-cover object-center group-hover:opacity-75"
            />
            <div className="p-2 flex flex-col">
                <h1 className="font-normal font-sans">
                    {name}
                </h1>
                <div className="flex items-center">
                    <StarIcon className="w-4 h-4 text-yellow-400" />
                    <span className="text-gray-500 text-sm">
                        {rating}
                    </span>
                    <span className="ms-[2px] text-gray-600 text-sm">
                        {`口コミ${nReview}件症例${nReceipt}件`}
                    </span>
                    <span className="ms-[2px] text-gray-600 text-sm">
                        {`|`}
                    </span>
                    <span className="ms-[2px] text-gray-600 text-sm">
                        {`イベント${nEvent}件`}
                    </span>
                </div>
            </div>
        </div>
    </Card>
)

const ClinicPage = () => {
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const partIndex = searchParams.get("part");
    const concernIndex = searchParams.get("concern");
    const treatmentIndex = searchParams.get("treatment");

    let length = 100;
    if(treatmentIndex) {
        length = 1;
    }
    else if(concernIndex) {
        length = 2;
    }

    if (location.pathname.startsWith("/bookmark")) {
        length = 2;
    }

    const targetData = data.filter((item, index)=>{
        return index < length;
    })

    return (
        <div>
            <div className="pt-3 px-2">
                <PageTitle>
                    クリニック一覧
                </PageTitle>
            </div>
            <div className="px-2 pt-2 text-sm">
                {`${targetData.length}件のクリニック`}
            </div>
            {targetData.map((item, index) => (
                <ClinicCard
                    key={index}
                    mainImg={item.mainImg}
                    name={item.name}
                    rating={item.rating}
                    nReview={item.nReview}
                    nReceipt={item.nReceipt}
                    nEvent={item.nEvent}
                />
            ))}
        </div>
    )
}

export default ClinicPage;