import React from "react";
import { data } from "./example";
import Card from "components/Card";
import { StarIcon } from "@heroicons/react/20/solid";
import PageTitle from "components/Title/Page";
import Search from "containers/Search";
import numeral from "numeral";
import { useSearchParams } from "react-router-dom";

export const ReviewCard = ({ rating, treatmentName, price, text, clinicName }) => (
    <Card>
        <div className="group rounded-md overflow-hidden">
            <div className="flex items-center">
                <div className="p-2 flex flex-col text-xs space-y-1.5 w-full">
                    <div className="flex items-center">
                        <StarIcon className="w-4 h-4 text-yellow-400" />
                        <span className="text-gray-500 text-xs">
                            {rating}
                        </span>
                    </div>
                    <div>
                        <h1 className="font-normal font-sans">
                            {treatmentName}
                        </h1>
                    </div>
                    <div className="flex items-center">
                        <span className="text-pink-700 text-xs">
                            {`¥${numeral(price).format("0,0")}`}
                        </span>
                    </div>
                    <div className="flex py-2">
                        <span className="text-sm">
                            {text}
                        </span>
                    </div>
                    <div className="flex justify-end">
                        <h1 className="font-normal font-sans text-gray-600">
                            {clinicName}
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    </Card>
);

const ReviewPage = () => {
    const [searchParams] = useSearchParams();
    const partIndex = searchParams.get("part");
    const concernIndex = searchParams.get("concern");
    const treatmentIndex = searchParams.get("treatment");

    let length = 100;
    if(treatmentIndex) {
        length = 1;
    }
    else if(concernIndex) {
        length = 2;
    }
    
    const targetData = data.filter((item, index)=>{
        return index < length;
    })

    return (
        <div>
            <div className="pt-3 px-2">
                <PageTitle>
                レビュ一覧
                </PageTitle>
            </div>
            <div className="px-2 pt-2 text-sm">
                {`${targetData.length}件のレビュ`}
            </div>
            {targetData.map((item, index) => (
                <ReviewCard
                    key={index}
                    rating={item.rating}
                    treatmentName={item.treatmentName}
                    price={item.price}
                    text={item.text}
                    clinicName={item.clinicName}
                />
            ))}
        </div>
    )
}

export default ReviewPage;