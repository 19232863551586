import React from "react";

const ReservationPage = ()=>{
    return (
        <div>
            <div>予約ページ</div>
        </div>
    )
}

export default ReservationPage;
