import Breadcrumbs from 'containers/Breadcrumbs';
import Tabs from 'components/Tabs';
import Tab from 'components/Tabs/Tab';
import ClinicPage from 'pages/Clinic';
import EventPage from 'pages/Event';
import React, { useEffect, useState } from 'react';
import { parts as allParts, concerns as allConcerns, treatments as allTreatments } from 'pages/Home/example';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import ReviewPage from 'pages/Review';
import _ from 'lodash';
import { useModal } from 'components/Modal/Simple';

const Contents = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const partIndex = searchParams.get('part');
    const concernIndex = searchParams.get('concern');
    const treatmentIndex = searchParams.get('treatment');
    const [pages, setPages] = useState(null);
    const [options, setOptions] = useState(null);
    const { Modal, openModal, closeModal } = useModal();

    console.log(partIndex);

    useEffect(() => {
        console.log("useEffect[partIndex]", "part", partIndex);
        if (_.isNil(partIndex)) {
            navigate(`/`);
            return;
        }
        const part = allParts[partIndex];
        const tempPages = [
            { name: part?.name, href: '#' },
        ]
        const tempConcerns = allConcerns.filter((elm) => elm.part === part?.name);
        console.log("tempConcerns", tempConcerns);
        setOptions(tempConcerns);
        setPages(tempPages);
    }, [partIndex])

    useEffect(() => {
        console.log("useEffect[partIndex, concernIndex]", "part", partIndex, "concern", concernIndex);
        if (_.isNil(partIndex)) {
            navigate(`/`);
            return;
        }
        if (_.isNil(concernIndex)) {
            const part = allParts[partIndex];
            const tempPages = [
                { name: part?.name, href: '#' },
            ]
            setPages(tempPages);
            const tempConcerns = allConcerns.filter((elm) => elm.part === part?.name);
            console.log("tempConcerns", tempConcerns);
            setOptions(tempConcerns);
            return;
        }
        const part = allParts[partIndex];
        const concern = allConcerns.find((elm) => elm.id === Number(concernIndex));
        const tempPages = [
            { name: part?.name, href: '#' },
            { name: concern?.name, href: '#' },
        ]
        const tempTreatments = allTreatments.filter((elm) => elm?.concernId === concern?.id);
        console.log("tempPages", tempTreatments);
        setOptions(tempTreatments);
        setPages(tempPages);
    }, [partIndex, concernIndex])

    useEffect(() => {
        console.log("useEffect[partIndex, concernIndex, treatmentIndex]", "part", partIndex, "concern", concernIndex, "treatment", treatmentIndex);
        if (_.isNil(partIndex)) {
            navigate(`/`);
            return;
        }
        if (_.isNil(concernIndex)) {
            navigate(`${location.pathname}?part=${partIndex}`);
            return;
        }
        if (_.isNil(treatmentIndex)) {
            const part = allParts[partIndex];
            const concern = allConcerns.find((elm) => elm.id === Number(concernIndex));
            const tempPages = [
                { name: part?.name, href: '#' },
                { name: concern?.name, href: '#' },
            ]
            setPages(tempPages);
            const tempOptions = allTreatments.filter((elm) => elm?.concernId === concern?.id);
            console.log("tempOptions", tempOptions);
            setOptions(tempOptions);
            return;
        }
        const part = allParts[partIndex];
        const concern = allConcerns.find((elm) => elm.id === Number(concernIndex));
        const treatment = allTreatments.find((elm) => elm.id === Number(treatmentIndex));
        const tempPages = [
            { name: part?.name, href: '#' },
            { name: concern?.name, href: '#' },
            { name: treatment?.name, href: '#' },
        ]
        setPages(tempPages);
    }, [partIndex, concernIndex, treatmentIndex])

    const onBreadcrumbsPlusClicked = (length) => {
        console.log("onBreadcrumbsClicked", length);
        openModal();
    }

    const onOptionClicked = (item) => {
        console.log("onOptionClicked", item);
        if (_.isNil(item)) {
            return;
        }
        if (_.isNil(partIndex)) {
            return;
        }
        if (_.isNil(item?.concernId)) {
            navigate(`${location.pathname}?part=${partIndex}&concern=${item?.id}`);
            closeModal();
            return;
        }
        navigate(`${location.pathname}?part=${partIndex}&concern=${concernIndex}&treatment=${item?.id}`);
        closeModal();
    }

    if (_.isNil(pages)) {
        return <div></div>
    }

    if (_.isNil(options)) {
        return <div></div>
    }

    return (
        <div>
            <div>
                <div className="px-2 pt-2 overflow-scroll">
                    <Breadcrumbs pages={pages} onPlusClicked={onBreadcrumbsPlusClicked} />
                </div>
                <Tabs tabsClassnames='sticky top-0 bg-white'>
                    <Tab title='イベント' path="events">
                        <EventPage />
                    </Tab>
                    <Tab title='クリニック' path="clinics">
                        <ClinicPage />
                    </Tab>
                    <Tab title='レビュー' path="reviews">
                        <ReviewPage />
                    </Tab>
                </Tabs>
            </div>
            <Modal>
                <div className="flex flex-col space-y-3">
                    {options.map((item, index) => (
                        <div className="shadow ring-1 ring-black ring-opacity-5 p-2" key={index} onClick={() => { onOptionClicked(item) }}>
                            {item?.name}
                        </div>
                    ))}
                </div>
            </Modal>
        </div>
    );
}

export default Contents;
