export const parts = [
    { name: '腹・背中' },
    { name: '胸' },
    { name: '歯' },
    { name: '首' },
    { name: '肩' },
    { name: '脇' },
    { name: '手' },
    { name: '腕' },
    { name: '脚' },
    { name: '婦人科' },
    { name: 'お尻' },
    { name: '全身' },
    { name: '漢方' },
    { name: 'その他' },
]

export const concerns = [
    { part: "腹・背中", name: "毛穴", id: 0 },
    { part: "腹・背中", name: "肌荒れ・ニキビ", id: 1 },
    { part: "腹・背中", name: "美白", id: 2 },
    { part: "胸", name: "シミ・そばかす・肝斑（かんぱん）", id: 3 },
    { part: "胸", name: "豊胸", id: 4 },
    { part: "歯", name: "白い歯", id: 5 },
    { part: "歯", name: "歯並び", id: 6 },
    { part: "首", name: "首を長く", id: 7 },
    { part: "首", name: "シワ", id: 8 },
    { part: "肩", name: "肩こり", id: 9 },
    { part: "脇", name: "ワキ汗・ワキガ・ワキの黒ずみ", id: 10 },
    { part: "手", name: "手汗", id: 11 },
    { part: "腕", name: "リフトアップ", id: 12 },
    { part: "脚", name: "痩身（下半身）", id: 13 },
    { part: "婦人科", name: "婦人科形成", id: 14 },
    { part: "お尻", name: "ヒップアップ", id: 15 },
    { part: "全身", name: "痩身（上半身）", id: 16 },
    { part: "漢方", name: "美容点滴・注射", id: 17 },
    { part: "その他", name: "乾燥肌", id: 18 },
    { part: "その他", name: "小顔", id: 19 },
    { part: "その他", name: "顔痩せ", id: 20 },
];

/*

鼻筋
骨切幅寄せ
ワシ鼻修正・ハンプ切除
斜鼻修正
眉間プロテーゼ
プロテーゼ除去
レディエッセ注入（鼻）
ヒアルロン酸注入（鼻）
隆鼻術

小鼻
鼻翼挙上
鼻孔縁挙上
鼻翼基部形成（貴族手術）
小鼻縮小（非切開）
ヒアルロン酸注入（鼻翼基部）
小鼻縮小（切開）

顔痩せ
脂肪冷却（輪郭）
メーラーファット除去
医療痩身（顔）
脂肪吸引注射（顔）
脂肪溶解注射（顔）
脂肪吸引（顔）
バッカルファット除去
ハイフ（顔）

横顔
セットバック
ルフォー+ssro（両あご)
脂肪注入（額）
額プロテーゼ
ヒアルロン酸注入（額）
鼻翼基部形成（貴族手術）
鼻柱下降・鼻唇角形成（猫手術）
レディエッセ注入（鼻）
ヒアルロン酸注入（鼻）
骨切り（あご）
あごプロテーゼ
ヒアルロン酸注入（あご）
レディエッセ注入（あご）
隆鼻術

クマ
下まぶたたるみ取り
下まぶたの脂肪取り
脂肪注入（肌）
PRP皮膚再生療法
ヒアルロン酸注入（目の下）
レーザートーニング（美白・肝斑）
ベビーコラーゲン
スネコス

鼻の下（人中）
人中短縮
鼻柱挙上
鼻柱下降・鼻唇角形成（猫手術）
ボトックス（人中）
ヒアルロン酸注入（唇）

アフターケア
インディバ（お腹）
インディバ（肩）
インディバ（二の腕）
インディバ（脚）
インディバ（お尻）
インディバ（顔）
インディバ（腰）
インディバ（背中）

シワ
脂肪注入（肌）
前額リフト
再生医療
切開リフト
シワ取りボトックス
鼻翼基部形成（貴族手術）
PRP皮膚再生療法
マイクロボトックス
ボトックス（眉間）
ボトックス（目尻）
ボトックス（額）
ベビーコラーゲン
スネコス
ネックリフト
ボトックス（首）
ヒアルロン酸注入（首）
ヒアルロン酸注入（鼻翼基部）
ボトックス（あご）
肌ヒアルロン酸注入（たるみ・シワ改善）

肌荒れ・ニキビ
ピーリング（ニキビ・毛穴）
イオン導入
美容点滴・美容注射（美肌）
エレクトロポレーション
光治療（フォトフェイシャルなど）
ウーバーピール
アグネス
ルメッカ
ニキビ治療

毛穴
ピーリング（ニキビ・毛穴）
ダーマペン
エレクトロポレーション
毛穴洗浄（ハイドラフェイシャルなど）
フラクショナルレーザー（毛穴・ニキビ跡）
医療脱毛（顔）
ニードルRF（ポテンツァなど）
赤み除去レーザー
ポテンツァ
ピコフラクショナルレーザー（毛穴・ニキビ跡）

シミ・そばかす・肝斑（かんぱん）
シミ取りレーザー
フォトRF
ピコレーザートーニング（美白・肝斑）
光治療（フォトフェイシャルなど）
レーザートーニング（美白・肝斑）
ルメッカ
ポテンツァ
ピコスポット

リフトアップ
前額リフト
切開リフト
PRP皮膚再生療法
マイクロボトックス
RF（高周波）たるみ治療
ニードルRF（ポテンツァなど）
ネックリフト
ポテンツァ
ハイフ（首）
ボトックス（首）
糸リフト
肌ヒアルロン酸注入（たるみ・シワ改善）
ハイフ（顔）

肌のハリ・ツヤ
エレクトロポレーション
ヴェルヴェットスキン
ピーリング（ハリ・ツヤ）
ミラノリピール
メソガン
水光注射（ハリ・美肌）

美白
白玉点滴・注射
エレクトロポレーション
ピコレーザートーニング（美白・肝斑）
光治療（フォトフェイシャルなど）
レーザートーニング（美白・肝斑）
ヴェルヴェットスキン
ピーリング（ハリ・ツヤ）
ウーバーピール
ルメッカ
ミラノリピール

アートメイク
アートメイク（眉）
アートメイク（唇）
アートメイク（アイライン）
アートメイク（ヘアライン）
アートメイク（ほくろ）
アートメイク（傷跡修正）

美容点滴・注射
美容点滴・美容注射（美肌）
白玉点滴・注射
プラセンタ注射
美容点滴・美容注射（ダイエット）
美容点滴・美容注射（疲労回復）
高濃度ビタミンC点滴

歯並び
矯正（ワイヤー）
セラミック
セットバック
矯正（マウスピース）
ラミネートベニア

白い歯
セラミック
ホワイトニング
ラミネートベニア

目の開き
上まぶたたるみ取り
眉下切開
前額リフト
眼瞼下垂（非切開）
眼瞼下垂（切開）

鼻の穴
鼻翼挙上
鼻孔縁挙上
鼻孔縁下降
鼻尖形成（非切開）
鼻中隔延長（非切開）
小鼻縮小（非切開）
鼻尖形成（切開）
鼻中隔延長（切開）
小鼻縮小（切開）

ニキビ跡
ピーリング（ニキビ・毛穴）
ダーマペン
光治療（フォトフェイシャルなど）
フラクショナルレーザー（毛穴・ニキビ跡）
ヴェルヴェットスキン
ピーリング（ハリ・ツヤ）
ニードルRF（ポテンツァなど）
ウーバーピール
ヴァンパイアフェイシャル
赤み除去レーザー
ルメッカ
ミラノリピール
ポテンツァ
ピコフラクショナルレーザー（毛穴・ニキビ跡）

ほくろ・いぼ
ほくろ・いぼ除去レーザー（肌）
アートメイク（ほくろ）
ほくろ・いぼ除去レーザー（首）
ほくろ・いぼ切除（肌）

赤ら顔
フォトRF
光治療（フォトフェイシャルなど）
赤み除去レーザー
ルメッカ

乾燥肌
エレクトロポレーション
メソガン
水光注射（ハリ・美肌）

脂性肌
ピーリング（ニキビ・毛穴）
毛穴洗浄（ハイドラフェイシャルなど）
マイクロボトックス

エラ
ボトックス（エラ・小顔）
骨削り（エラ）

あご
セットバック
ルフォー+ssro（両あご)
あご下筋肉縛り
骨切り（あご）
あごプロテーゼ
ヒアルロン酸注入（あご）
ボトックス（あご）
レディエッセ注入（あご）

小顔
ルフォー+ssro（両あご)
脂肪注入（額）
額プロテーゼ
ヒアルロン酸注入（額）
ヒアルロン酸注入（頬）
脂肪冷却（輪郭）
メーラーファット除去
ヒアルロン酸注入（こめかみ）
脂肪吸引注射（顔）
ボトックス（エラ・小顔）
骨削り（エラ）
骨切り（あご）
あごプロテーゼ
骨削り（頬）
脂肪溶解注射（顔）
ヒアルロン酸注入（あご）
レディエッセ注入（あご）
脂肪吸引（顔）
バッカルファット除去
ハイフ（顔）

豊胸
胸修正
豊胸（シリコン＋脂肪併用）
乳房再建
豊胸（シリコンバッグ）
豊胸（脂肪注入）

唇
口唇縮小術
アートメイク（唇）
ボトックス（人中）
ヒアルロン酸注入（唇）

歯茎
ガミースマイル手術
ボトックス（ガミースマイル）

口角
ボトックス（口角）
口角挙上

口元
人中短縮
口唇縮小術
セットバック
ルフォー+ssro（両あご)
アートメイク（唇）
鼻翼基部形成（貴族手術）
ジョールファット除去
えくぼ形成
ボトックス（人中）
ボトックス（ガミースマイル）
骨切り（あご）
あごプロテーゼ
ヒアルロン酸注入（あご）
ボトックス（あご）
レディエッセ注入（あご）
ヒアルロン酸注入（唇）

首を長く
ボトックス（肩）
ネックリフト
ハイフ（首）

肩こり
ボトックス（肩）
インディバ（肩）

ワキ汗・ワキガ・ワキの黒ずみ
医療脱毛（ワキ）
ワキ汗治療（ボトックス）
ワキ汗治療（レーザー）
ワキ汗治療（切開）
黒ずみ除去（脇）

血管
脂肪注入（肌）
ハンドベイン治療

手汗
多汗症治療（ボトックス）

ヒップアップ
ヒップアップ手術
脂肪注入（お尻）
ハイフ（お尻）

脱毛
医療脱毛（顔）
医療脱毛（全身）
医療脱毛（ワキ）
医療脱毛（VIO）
医療脱毛（腕）
医療脱毛（脚）
医療脱毛（うなじ）
医療脱毛（指）
医療脱毛（背中）
医療脱毛（お腹）
医療脱毛（胸）

痩身（上半身）
脂肪溶解注射（二の腕）
脂肪溶解注射（お腹）
医療痩身（お腹）
脂肪冷却（お腹）
ボトックス（二の腕）
脂肪吸引（腰）
脂肪吸引（背中）
脂肪吸引（肩）
ハイフ（二の腕）
医療痩身（二の腕）
脂肪冷却（二の腕）
ハイフ（背中・ブラファット）
ハイフ（お腹）
脂肪冷却（背中・ブラファット）
脂肪吸引（お腹）
脂肪吸引（二の腕）

修正
目元修正
鼻修正
輪郭修正
胸修正
傷跡修正
タトゥー除去
アートメイク（傷跡修正）
アートメイク除去・修正

へそ
へそ形成

乳輪・乳頭
胸修正
モントゴメリー腺除去
乳輪縮小
乳頭縮小
陥没乳頭形成
医療脱毛（胸）
黒ずみ除去（胸）

婦人科形成
小陰唇縮小
膣縮小（切開）
陰核（クリトリス）包茎
黒ずみ除去（デリケートゾーン）
膣縮小（非切開）
副皮除去
脂肪注入（大陰唇）
大陰唇たるみ除去
会陰部贅皮切除
処女膜切開
処女膜再生・形成
すそワキガ治療

耳
ピアス穴あけ
立ち耳修正
耳垂縮小（耳たぶ縮小）
ピアス穴修正
ヒアルロン酸注入（耳たぶ）

痩身（下半身）
脂肪溶解注射（太もも）
脂肪溶解注射（お尻）
ボトックス（太もも）
ハイフ（太もも）
ハイフ（膝上）
医療痩身（太もも）
脂肪冷却（太もも）
医療痩身（お尻）
脂肪冷却（お尻）
ボトックス（ふくらはぎ）
脂肪吸引（脚）
脂肪吸引（お尻）

FAGA・薄毛・植毛
FAGA・薄毛・植毛

たれ目・つり目
タレ目形成・グラマラスライン（切開）
ボトックス（タレ目）
目尻靭帯移動
脂肪注入（目の下）
タレ目形成・グラマラスライン（埋没）
ヒアルロン酸注入（涙袋）

離れ目・寄り目
目尻切開
蒙古ひだ形成
目頭切開

鼻先
軟骨移植
真皮移植
鼻尖形成（非切開）
鼻中隔延長（非切開）
鼻柱挙上
鼻尖形成（切開）
鼻中隔延長（切開）

二重
上まぶたの脂肪取り
眉下切開
自然癒着法
眼瞼下垂（非切開）
二重埋没
眼瞼下垂（切開）
二重切開

涙袋
脂肪注入（目の下）
ヒアルロン酸注入（涙袋）
*/

export const treatments = [
    { id: 0, concernId: 0, name: "ピーリング（ニキビ・毛穴）" },
    { id: 1, concernId: 0, name: "ダーマペン" },
    { id: 2, concernId: 0, name: "エレクトロポレーション" },
    { id: 3, concernId: 0, name: "毛穴洗浄（ハイドラフェイシャルなど）" },
    { id: 4, concernId: 0, name: "フラクショナルレーザー（毛穴・ニキビ跡）" },
    { id: 5, concernId: 0, name: "医療脱毛（顔）" },
    { id: 6, concernId: 0, name: "ニードルRF（ポテンツァなど）" },
    { id: 7, concernId: 0, name: "赤み除去レーザー" },
    { id: 8, concernId: 0, name: "ポテンツァ" },
    { id: 9, concernId: 0, name: "ピコフラクショナルレーザー（毛穴・ニキビ跡）" },
    { id: 10, concernId: 1, name: "ピーリング（ニキビ・毛穴）" },
    { id: 11, concernId: 1, name: "イオン導入" },
    { id: 12, concernId: 1, name: "美容点滴・美容注射（美肌）" },
    { id: 13, concernId: 1, name: "エレクトロポレーション" },
    { id: 14, concernId: 1, name: "光治療（フォトフェイシャルなど）" },
    { id: 15, concernId: 1, name: "ウーバーピール" },
    { id: 16, concernId: 1, name: "アグネス" },
    { id: 17, concernId: 1, name: "ルメッカ" },
    { id: 18, concernId: 1, name: "ニキビ治療" },
    { id: 19, concernId: 2, name: "白玉点滴・注射" },
    { id: 20, concernId: 2, name: "エレクトロポレーション" },
    { id: 21, concernId: 2, name: "ピコレーザートーニング（美白・肝斑）" },
    { id: 22, concernId: 2, name: "光治療（フォトフェイシャルなど）" },
    { id: 23, concernId: 2, name: "レーザートーニング（美白・肝斑）" },
    { id: 24, concernId: 2, name: "ヴェルヴェットスキン" },
    { id: 25, concernId: 2, name: "ピーリング（ハリ・ツヤ）" },
    { id: 26, concernId: 2, name: "ウーバーピール" },
    { id: 27, concernId: 2, name: "ルメッカ" },
    { id: 28, concernId: 2, name: "ミラノリピール" },
    // 치료 옵션을 계속 추가하겠습니다.
    // 예를 들어, concernId: 3 (シミ・そばかす・肝斑（かんぱん）)에 대한 치료 목록입니다.
    { id: 29, concernId: 3, name: "シミ取りレーザー" },
    { id: 30, concernId: 3, name: "フォトRF" },
    { id: 31, concernId: 3, name: "ピコレーザートーニング（美白・肝斑）" },
    { id: 32, concernId: 3, name: "光治療（フォトフェイシャルなど）" },
    { id: 33, concernId: 3, name: "レーザートーニング（美白・肝斑）" },
    { id: 34, concernId: 3, name: "ルメッカ" },
    { id: 35, concernId: 3, name: "ポテンツァ" },
    { id: 36, concernId: 3, name: "ピコスポット" },
    // concernId: 4 (リフトアップ)에 대한 치료 목록입니다.
    { id: 37, concernId: 4, name: "前額リフト" },
    { id: 38, concernId: 4, name: "切開リフト" },
    { id: 39, concernId: 4, name: "PRP皮膚再生療法" },
    { id: 40, concernId: 4, name: "マイクロボトックス" },
    { id: 41, concernId: 4, name: "RF（高周波）たるみ治療" },
    { id: 42, concernId: 4, name: "ニードルRF（ポテンツァなど）" },
    { id: 43, concernId: 4, name: "ネックリフト" },
    { id: 44, concernId: 4, name: "ポテンツァ" },
    { id: 45, concernId: 4, name: "ハイフ（首）" },
    { id: 46, concernId: 4, name: "ボトックス（首）" },
    { id: 47, concernId: 4, name: "糸リフト" },
    { id: 48, concernId: 4, name: "肌ヒアルロン酸注入（たるみ・シワ改善）" },
    { id: 49, concernId: 4, name: "ハイフ（顔）" },
    // concernId: 5 (肌のハリ・ツヤ)에 대한 치료 목록입니다.
    { id: 50, concernId: 5, name: "エレクトロポレーション" },
    { id: 51, concernId: 5, name: "ヴェルヴェットスキン" },
    { id: 52, concernId: 5, name: "ピーリング（ハリ・ツヤ）" },
    { id: 53, concernId: 5, name: "ミラノリピール" },
    { id: 54, concernId: 5, name: "メソガン" },
    { id: 55, concernId: 5, name: "水光注射（ハリ・美肌）" },
    // concernId: 6 (美白)에 대한 치료 목록입니다.
    { id: 56, concernId: 6, name: "白玉点滴・注射" },
    { id: 57, concernId: 6, name: "エレクトロポレーション" },
    { id: 58, concernId: 6, name: "ピコレーザートーニング（美白・肝斑）" },
    { id: 59, concernId: 6, name: "光治療（フォトフェイシャルなど）" },
    { id: 60, concernId: 6, name: "レーザートーニング（美白・肝斑）" },
    { id: 61, concernId: 6, name: "ヴェルヴェットスキン" },
    { id: 62, concernId: 6, name: "ピーリング（ハリ・ツヤ）" },
    { id: 63, concernId: 6, name: "ウーバーピール" },
    { id: 64, concernId: 6, name: "ルメッカ" },
    { id: 65, concernId: 6, name: "ミラノリピール" },
    // concernId: 7 (歯並び)에 대한 치료 목록입니다.
    { id: 66, concernId: 7, name: "矯正（ワイヤー）" },
    { id: 67, concernId: 7, name: "セラミック" },
    { id: 68, concernId: 7, name: "セットバック" },
    { id: 69, concernId: 7, name: "矯正（マウスピース）" },
    { id: 70, concernId: 7, name: "ラミネートベニア" },
    // concernId: 8 (白い歯)에 대한 치료 목록입니다.
    { id: 71, concernId: 8, name: "セラミック" },
    { id: 72, concernId: 8, name: "ホワイトニング" },
    { id: 73, concernId: 8, name: "ラミネートベニア" },
    // concernId: 9 (目の開き)에 대한 치료 목록입니다.
    { id: 74, concernId: 9, name: "上まぶたたるみ取り" },
    { id: 75, concernId: 9, name: "眉下切開" },
    { id: 76, concernId: 9, name: "前額リフト" },
    { id: 77, concernId: 9, name: "眼瞼下垂（非切開）" },
    { id: 78, concernId: 9, name: "眼瞼下垂（切開）" },
    // concernId: 10 (鼻の穴)에 대한 치료 목록입니다.
    { id: 79, concernId: 10, name: "鼻翼挙上" },
    { id: 80, concernId: 10, name: "鼻孔縁挙上" },
    { id: 81, concernId: 10, name: "鼻孔縁下降" },
    { id: 82, concernId: 10, name: "鼻尖形成（非切開）" },
    { id: 83, concernId: 10, name: "鼻中隔延長（非切開）" },
    { id: 84, concernId: 10, name: "小鼻縮小（非切開）" },
    { id: 85, concernId: 10, name: "鼻尖形成（切開）" },
    { id: 86, concernId: 10, name: "鼻中隔延長（切開）" },
    { id: 87, concernId: 10, name: "小鼻縮小（切開）" },
    // concernId: 11 (二重)에 대한 치료 목록입니다.
    { id: 88, concernId: 11, name: "上まぶたの脂肪取り" },
    { id: 89, concernId: 11, name: "眉下切開" },
    { id: 90, concernId: 11, name: "自然癒着法" },
    { id: 91, concernId: 11, name: "眼瞼下垂（非切開）" },
    { id: 92, concernId: 11, name: "二重埋没" },
    { id: 93, concernId: 11, name: "眼瞼下垂（切開）" },
    { id: 94, concernId: 11, name: "二重切開" },
    // concernId: 12 (涙袋)에 대한 치료 목록입니다.
    { id: 95, concernId: 12, name: "脂肪注入（目の下）" },
    { id: 96, concernId: 12, name: "ヒアルロン酸注入（涙袋）" },
    // concernId: 13 (たれ目・つり目)에 대한 치료 목록입니다.
    { id: 97, concernId: 13, name: "タレ目形成・グラマラスライン（切開）" },
    { id: 98, concernId: 13, name: "ボトックス（タレ目）" },
    { id: 99, concernId: 13, name: "目尻靭帯移動" },
    { id: 100, concernId: 13, name: "脂肪注入（目の下）" },
    { id: 101, concernId: 13, name: "タレ目形成・グラマラスライン（埋没）" },
    { id: 102, concernId: 13, name: "ヒアルロン酸注入（涙袋）" },
    // concernId: 14 (離れ目・寄り目)에 대한 치료 목록입니다.
    { id: 103, concernId: 14, name: "目尻切開" },
    { id: 104, concernId: 14, name: "蒙古ひだ形成" },
    { id: 105, concernId: 14, name: "目頭切開" },
    // concernId: 15 (鼻先)에 대한 치료 목록입니다.
    { id: 106, concernId: 15, name: "軟骨移植" },
    { id: 107, concernId: 15, name: "真皮移植" },
    { id: 108, concernId: 15, name: "鼻尖形成（非切開）" },
    { id: 109, concernId: 15, name: "鼻中隔延長（非切開）" },
    { id: 110, concernId: 15, name: "鼻柱挙上" },
    { id: 111, concernId: 15, name: "鼻尖形成（切開）" },
    { id: 112, concernId: 15, name: "鼻中隔延長（切開）" },
    // concernId: 16 (へそ)에 대한 치료 목록입니다.
    { id: 113, concernId: 16, name: "へそ形成" },
    // concernId: 17 (乳輪・乳頭)에 대한 치료 목록입니다.
    { id: 114, concernId: 17, name: "胸修正" },
    { id: 115, concernId: 17, name: "モントゴメリー腺除去" },
    { id: 116, concernId: 17, name: "乳輪縮小" },
    { id: 117, concernId: 17, name: "乳頭縮小" },
    { id: 118, concernId: 17, name: "陥没乳頭形成" },
    { id: 119, concernId: 17, name: "医療脱毛（胸）" },
    { id: 120, concernId: 17, name: "黒ずみ除去（胸）" },
    // concernId: 18 (婦人科形成)에 대한 치료 목록입니다.
    { id: 121, concernId: 18, name: "小陰唇縮小" },
    { id: 122, concernId: 18, name: "膣縮小（切開）" },
    { id: 123, concernId: 18, name: "陰核（クリトリス）包茎" },
    { id: 124, concernId: 18, name: "黒ずみ除去（デリケートゾーン）" },
    { id: 125, concernId: 18, name: "膣縮小（非切開）" },
    { id: 126, concernId: 18, name: "副皮除去" },
    { id: 127, concernId: 18, name: "脂肪注入（大陰唇）" },
    { id: 128, concernId: 18, name: "大陰唇たるみ除去" },
    { id: 129, concernId: 18, name: "会陰部贅皮切除" },
    { id: 130, concernId: 18, name: "処女膜切開" },
    { id: 131, concernId: 18, name: "処女膜再生・形成" },
    { id: 132, concernId: 18, name: "すそワキガ治療" },
    // concernId: 19 (耳)에 대한 치료 목록입니다.
    { id: 133, concernId: 19, name: "ピアス穴あけ" },
    { id: 134, concernId: 19, name: "立ち耳修正" },
    { id: 135, concernId: 19, name: "耳垂縮小（耳たぶ縮小）" },
    { id: 136, concernId: 19, name: "ピアス穴修正" },
    { id: 137, concernId: 19, name: "ヒアルロン酸注入（耳たぶ）" },
    // concernId: 20 (痩身（下半身）)에 대한 치료 목록입니다.
    { id: 138, concernId: 20, name: "脂肪溶解注射（太もも）" },
    { id: 139, concernId: 20, name: "脂肪溶解注射（お尻）" },
    { id: 140, concernId: 20, name: "ボトックス（太もも）" },
    { id: 141, concernId: 20, name: "ハイフ（太もも）" },
    { id: 142, concernId: 20, name: "ハイフ（膝上）" },
    { id: 143, concernId: 20, name: "医療痩身（太もも）" },
    { id: 144, concernId: 20, name: "脂肪冷却（太もも）" },
    { id: 145, concernId: 20, name: "医療痩身（お尻）" },
    { id: 146, concernId: 20, name: "脂肪冷却（お尻）" },
    { id: 147, concernId: 20, name: "ボトックス（ふくらはぎ）" },
    { id: 148, concernId: 20, name: "脂肪吸引（脚）" },
    { id: 149, concernId: 20, name: "脂肪吸引（お尻）" },
    // concernId: 21 (脱毛)에 대한 치료 목록입니다.
    { id: 150, concernId: 21, name: "医療脱毛（全身）" },
    { id: 151, concernId: 21, name: "医療脱毛（VIO）" },
    { id: 152, concernId: 21, name: "医療脱毛（腕）" },
    { id: 153, concernId: 21, name: "医療脱毛（脚）" },
    { id: 154, concernId: 21, name: "医療脱毛（うなじ）" },
    { id: 155, concernId: 21, name: "医療脱毛（指）" },
    { id: 156, concernId: 21, name: "医療脱毛（背中）" },
    { id: 157, concernId: 21, name: "医療脱毛（お腹）" },
    { id: 158, concernId: 21, name: "医療脱毛（胸）" },
    // concernId: 22 (ワキ汗・ワキガ・ワキの黒ずみ)에 대한 치료 목록입니다.
    { id: 159, concernId: 22, name: "医療脱毛（ワキ）" },
    { id: 160, concernId: 22, name: "ワキ汗治療（ボトックス）" },
    { id: 161, concernId: 22, name: "ワキ汗治療（レーザー）" },
    { id: 162, concernId: 22, name: "ワキ汗治療（切開）" },
    { id: 163, concernId: 22, name: "黒ずみ除去（脇）" },
    // concernId: 23 (手汗)에 대한 치료 목록입니다.
    { id: 164, concernId: 23, name: "多汗症治療（ボトックス）" },
    // concernId: 24 (ヒップアップ)에 대한 치료 목록입니다.
    { id: 165, concernId: 24, name: "ヒップアップ手術" },
    { id: 166, concernId: 24, name: "脂肪注入（お尻）" },
    { id: 167, concernId: 24, name: "ハイフ（お尻）" },
    // concernId: 25 (手・足の冷え)에 대한 치료 목록입니다.
    { id: 168, concernId: 25, name: "ハンドベイン治療" },
];