import React, { useEffect } from 'react'
import _ from 'lodash'
import { classNames } from 'components/uiUtils'
import { useNavigate, useLocation, useParams } from 'react-router-dom'

const titleToPath = (title) => title.toLowerCase().replace(" ", "")

export default ({ children, tabsClassnames = "" }) => {
    const { tabId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!_.isArray(children)) children = [children];
        console.log("current tabId", tabId, children)
        if (_.isNil(tabId)) {
            if (!_.isNil(children[0])) {
                console.log("navigate to default", children[0].props.title, titleToPath(children[0].props.title));
                navigate(`${titleToPath(children[0].props.path)}`)
            }
        }
    }, [])

    const childrenProps = children.map((child) => child.props)
    const currentTab = _.find(children, (elm) => elm.props.path === tabId);

    return (
        <div>
            <div className={classNames("overflow-scroll border-b border-gray-200", tabsClassnames)}>
                <nav className="flex" aria-label="Tabs">
                    {childrenProps.map((tab) => (
                        <label
                            key={tab.title}
                            className={classNames(
                                tab.path === tabId
                                    ? 'border-indigo-500 text-indigo-600'
                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                'whitespace-nowrap border-b-2 py-3 px-7 text-sm font-medium'
                            )}
                            onClick={(() => { navigate(`${tab.path}${location.search}`) })}
                        >
                            {tab.title}
                        </label>
                    ))}
                </nav>
            </div>
            {currentTab}
        </div>
    )
}
