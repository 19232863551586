export const data = [
    {
        clinicName: "L'amour clinic Tokyo",
        treatmentName: "光治療（フォトフェイシャルなど） / その他（肌）",
        text: "まだ新しいクリニックの為院内は綺麗で比較的予約も取りやすくおすすめです",        
        rating: 9.8,
        price: 84500,
    },
    {
        clinicName: "Karis Beauty Clinic",
        treatmentName: "エレクトロポレーション / 光治療（フォトフェイシャルなど） / ルメッカ",
        text: "久しぶりに通いたいと思えるクリニックを見つけることができました。本当にいいクリニックさんだと思います！",        
        rating: 9.8,
        price: 84500,

    },
    {
        clinicName: "シティタワー新宿クリニック",
        treatmentName: "ホワイトニング",
        text: "せっかくホワイトニングをしてもらったので長く白さを保てるよう歯磨きは持ち歩こうと決意しました。",        
        rating: 9.8,
        price: 84500,

    },
    {
        clinicName: "日本美容・医療クリニック",
        treatmentName: "ボトックス（肩）",
        text: "肩に200の予定でしたが、首筋から肩にかけての張りが気になると看護師さんに相談したところ、首と肩で分けてはどうかと提案いただきました。その後、ドクターに初めての場合、首40肩160を勧められ施術を受けました。(40以上だと頭の重さが首に来る場合があるそうです。足りなかった場合次回調整がよいとのこと)きちんと説明、提案をしてくださったので安心して施術を受けることができました！",        
        rating: 9.8,
        price: 84500,

    },
]