import { Routes, Route, Navigate } from "react-router-dom";
import React from "react";
import HomePage from "pages/Home";
import ReviewPage from "pages/Review";
import MyPage from "pages/MyPage";
import ClinicPage from "pages/Clinic";
import EventPage from "pages/Event";
import Contents from "pages/Contents";
import BookmarkPage from "pages/Bookmark";
import SearchPage from "pages/Search";
import ReservationPage from "pages/Reservation";

export default () => {
    return (
        <Routes>
            <Route path="/" element={<Navigate to={'/home/'} />}></Route>
            <Route path="/home" element={<HomePage />}></Route>
            <Route path="/contents" element={<Contents />}>
                <Route path=":tabId" element={<Contents />}></Route>
            </Route>
            <Route path="/search" element={<SearchPage />}></Route>
            <Route path="/reservation" element={<ReservationPage />}></Route>
            <Route path="/bookmark" element={<BookmarkPage />}>
                <Route path=":tabId" element={<BookmarkPage />}></Route>
            </Route>
            <Route path="/my-page" element={<MyPage />}></Route>
        </Routes>
    )
}