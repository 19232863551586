import React from "react";
import { UserIcon, ChevronRightIcon, BanknotesIcon } from "@heroicons/react/24/outline";
import PageTitle from "components/Title/Page";

const MyPage = () => {
    return (
        <div className="p-2 flex flex-col space-y-2">
            <PageTitle>
                マイページ
            </PageTitle>
            <div className="shadow ring-1 ring-black ring-opacity-5 w-full">
                <div className="flex p-2">
                    <div className="mr-4 flex items-center">
                        <div className="rounded-full border border-pink-500 p-1 text-pink-500">
                            <UserIcon className="size-8" />
                        </div>
                        <div>
                            <div className="flex items-center">
                                <h4 className="text-lg font-bold ps-3">さくら</h4>
                                <ChevronRightIcon className="size-4" />
                            </div>
                            <div>
                                <h4 className="text-sm px-3 text-gray-500">sakura0401</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shadow ring-1 ring-black ring-opacity-5 w-full">
                <div className="flex p-2">
                    <div className="mr-4 flex items-center">
                        <div className="rounded-full border border-pink-500 text-pink-500 p-1">
                            <BanknotesIcon className="size-8" />
                        </div>
                        <div>
                            <div className="flex items-center">
                                <h4 className="text-lg font-bold ps-3">3,500P</h4>
                            </div>
                            <div className="flex items-center">
                                <h4 className="text-xs ps-3 text-gray-500">使い方・利用履歴を見る</h4>
                                <ChevronRightIcon className="size-3" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shadow ring-1 ring-black ring-opacity-5 w-full">
                <div className="flex p-2">
                    <div className="mr-4 flex items-center">
                        <div>
                            <div className="flex items-center">
                                <h4 className="text-lg ps-3">謝礼申請</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shadow ring-1 ring-black ring-opacity-5 w-full">
                <div className="flex p-2">
                    <div className="mr-4 flex items-center">
                        <div>
                            <div className="flex items-center">
                                <h4 className="text-lg ps-3">お知らせ</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shadow ring-1 ring-black ring-opacity-5 w-full">
                <div className="flex p-2">
                    <div className="mr-4 flex items-center">
                        <div>
                            <div className="flex items-center">
                                <h4 className="text-lg ps-3">クリップ</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shadow ring-1 ring-black ring-opacity-5 w-full">
                <div className="flex p-2">
                    <div className="mr-4 flex items-center">
                        <div>
                            <div className="flex items-center">
                                <h4 className="text-lg ps-3">興味</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shadow ring-1 ring-black ring-opacity-5 w-full">
                <div className="flex p-2">
                    <div className="mr-4 flex items-center">
                        <div>
                            <div className="flex items-center">
                                <h4 className="text-lg ps-3">予約一覧</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shadow ring-1 ring-black ring-opacity-5 w-full">
                <div className="flex p-2">
                    <div className="mr-4 flex items-center">
                        <div>
                            <div className="flex items-center">
                                <h4 className="text-lg ps-3">クーポンコード</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="shadow ring-1 ring-black ring-opacity-5 w-full">
                <div className="flex p-2">
                    <div className="mr-4 flex items-center">
                        <div>
                            <div className="flex items-center">
                                <h4 className="text-lg ps-3">ヘルプ</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shadow ring-1 ring-black ring-opacity-5 w-full">
                <div className="flex p-2">
                    <div className="mr-4 flex items-center">
                        <div>
                            <div className="flex items-center">
                                <h4 className="text-lg ps-3">お問い合わせ</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shadow ring-1 ring-black ring-opacity-5 w-full">
                <div className="flex p-2">
                    <div className="mr-4 flex items-center">
                        <div>
                            <div className="flex items-center">
                                <h4 className="text-lg ps-3">設定</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyPage;