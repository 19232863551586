import React from "react";
import Tabs from "components/Tabs";
import Tab from "components/Tabs/Tab";
import EventPage from "pages/Event";
import ClinicPage from "pages/Clinic";

const BookmarkPage = () => {
    return (
        <div>
        <Tabs tabsClassnames='sticky top-0 bg-white'>
            <Tab title='イベント' path="events">
                <EventPage />
            </Tab>
            <Tab title='クリニック' path="clinics">
                <ClinicPage />
            </Tab>
        </Tabs>
    </div>
    )
}

export default BookmarkPage;
